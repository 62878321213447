import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import { DashboardOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import chatIcon from 'src/Assets/Icons/chatbot.png'

// Routes 
import { useLocation, useNavigate } from 'react-router-dom';

import solidLogo from '../../Assets/Logo/solid-logo.png'
import { createContext, useContext, useState } from "react"
import ProfileButton from './profileButton';

const SidebarContext = createContext();

const menuList = [
    { Icon: <DashboardOutlined />, title: 'Dashboard', url: '/app/dashboard' },
    { Icon: <AddIcon />, title: 'Create Order', url: '/app/create-order' },
    { Icon: <LocalMallOutlinedIcon />, title: 'Orders', url: '/app/order' },
    { Icon: <ReceiptOutlinedIcon />, title: 'Invoice', url: '/app/invoice' },
    { Icon: <ArchiveOutlinedIcon />, title: 'Archived Invoices', url: '/app/archived-invoices' },
    // { Icon: <MarkUnreadChatAltOutlinedIcon />, title: 'Quotation', url: '/app/quotation' },
    { Icon: <WebOutlinedIcon />, title: 'Web Orders', url: '/app/web-orders' },
    // { Icon:  <img className="w-12 h-12" alt="Chatbot" src={chatIcon} />, title: 'Chatbot', url: '/app/chatbot' },
]

export default function Sidebar() {
    let userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const [expanded, setExpanded] = useState(false)
    const location = useLocation()

    return (
        <>
            <aside className="h-screen">
                <nav className="h-full flex flex-col bg-white border-r shadow-md">
                    <div className="px-4 py-1.5 pb-2 space-x-2 flex justify-between items-center">
                        <img src={solidLogo} className={`overflow-hidden transition-all ${expanded ? " w-32 " : "w-0"}`} />
                        <button onClick={() => setExpanded((curr) => !curr)} className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100">
                            {expanded ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </button>
                    </div>

                    <SidebarContext.Provider value={{ expanded }}>
                        <ul className="flex-1 ">
                            {menuList?.map((menu, i) => {
                                return <SidebarItem
                                    url={menu?.url}
                                    active={location?.pathname === menu?.url}
                                    icon={menu?.Icon}
                                    text={menu?.title}
                                    key={i} />
                            })}
                        </ul>
                    </SidebarContext.Provider>

                    <div className="border-t flex p-3">
                        <ProfileButton username={userinfo?.username} />
                        <div className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? "w-52 ml-2" : "w-0"} `}>
                            <div className="leading-4">
                                <h4 className="font-semibold capitalize">Hi, {userinfo?.username}</h4>
                                <span className="text-xs text-gray-600">{userinfo?.email}</span>
                            </div>
                            <MenuIcon size={20} />
                        </div>
                    </div>
                </nav>
            </aside>
        </>
    )
}

export function SidebarItem({ icon, text, active, url }) {
    const { expanded } = useContext(SidebarContext)
    const navigator = useNavigate()

    return (
        <li role='button' onClick={() => navigator(url)} className={`mx-2 relative flex my-1 font-medium rounded-md cursor-pointer transition-colors group ${active ? "bg-gradient-to-tr from-gray-700 to-gray-400 text-white" : "hover:bg-gray-200 text-gray-600"}`}>
            <Tooltip arrow title={text} placement="right">
                <div className=' h-10 flex justify-start items-center py-2 px-3'>
                    {icon}
                    <span className={`overflow-hidden transition-all ${expanded ? "min-w-[13rem] ml-3" : "w-0"}`}>{text}</span>
                    {!expanded && (
                        <div className={`absolute left-full rounded-md px-2 py-1 ml-6 bg-gray-100 text-gray-800 text-sm invisible opacity-20 -translate-x-3 transition-all group-hover:visible group-hover:opacity-100 group-hover:translate-x-0`}>
                            {text}
                        </div>
                    )}
                </div>
            </Tooltip>

        </li>
    )
}

