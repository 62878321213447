import { OpenInFullSharp, Refresh, Send } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import chatIcon from 'src/Assets/Icons/Gemini_Generate.jpeg'
import ImageWithFallbackAndLoader from "src/Components/ImageLoader";
import { CHATBOT_API } from "src/Services/Redux/Actions/user";
import orderBoxImage from 'src/Assets/Images/order-box.png'
import Close from "@mui/icons-material/Close";
import ScrollToBottom from 'react-scroll-to-bottom';

const renderTextWithBold = (text, i) => {
    // Regex to match text between ** **
    let parts = ''
    try {
        parts = text?.split(/\*\*(.*?)\*\*/g);
    } catch (error) {
        console.log(i)
        console.error(error)
        return
    }

    return parts?.map((part, index) => {
        if (index % 2 === 1) {
            // This part is inside **, so we wrap it with <strong> and apply Tailwind for bold
            return (
                <strong key={index} className="font-bold">
                    {part} <br />
                </strong>
            );
        }
        // Normal text
        return part;
    });
};

const ChatroomLayout = ({ setOpen }) => {
    // console.log(JSON.parse('chathistory'))
    const messages = useSelector(state => state?.userReducer?.chatbotMessages)
    const chatapiCall  = useSelector(state => state?.userReducer?.chatapiCall)
    // ACTION_CHATBOT_MESSAGES
    const [input, setInput] = useState('');
    const dispatch = useDispatch()
    const [question, setQuestion] = useState(null)
    const [answers, setAnswers] = useState([])
    const [questionNoToAsk, setQuestionNoToAsk] = useState(null)
    const navigate = useNavigate()
    const scrollRef = useRef(null);

    const scrollDownBy200px = () => {
        if (scrollRef.current) {
            const scrollableElement = scrollRef.current;
            scrollableElement.scrollTop += 200; // Scroll down by 200px
        }
    };

    const handleDataMaping = async (res) => {
        if (res?.isQuestionAsked) {
            setQuestion(res)
            setQuestionNoToAsk(0)
            dispatch({ type: 'ACTION_CHATBOT_MESSAGES', data: { sender: 'bot', text: `${res?.ques[0]} ?` } })
        } else if (res?.headers?.length === 0) {
            dispatch({ type: 'ACTION_CHATBOT_MESSAGES', data: { sender: 'bot', text: res?.answer } })
        } else if (res?.headers?.length > 0) {
            for (let answer of res?.answer) {
                let ans = ''
                let index = 0
                for (let v of res?.headers) {
                    if (v !== 'IMAGE_URL' && v !== 'IMAGEID') {
                        ans += `${answer[v]} ${index === 0 ? '/' : ''} `
                    }
                    index += 1
                }
                let item = { sender: 'bot', text: ans, IMAGE_URL: answer?.IMAGE_URL, IMAGEID: answer?.IMAGEID };
                dispatch({ type: 'ACTION_CHATBOT_MESSAGES', data: item })
            }
        }
        scrollDownBy200px()
    }

    // Function to handle sending a message
    const sendMessage = async () => {
        if (!input.trim()) return;
        let paylaod = { question: input }
        dispatch({ type: 'ACTION_CHATBOT_MESSAGES', data: { sender: 'user', text: input } })
        setInput('');
        dispatch(CHATBOT_API(paylaod)).then((res) => {
            handleDataMaping(res)
        }).catch((err) => {
            console.log(err)
        })
    };

    // Handle form submission (send message on Enter)
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (question?.ques) {
            if ((question?.ques?.length !== 0 && questionNoToAsk !== question?.ques?.length) && input.trim()) {
                let ans = [...answers, input.trim()]
                dispatch({ type: 'ACTION_CHATBOT_MESSAGES', data: { sender: 'user', text: input.trim() } })
                await setAnswers(ans)
                if (questionNoToAsk !== question?.ques?.length - 1) {
                    dispatch({ type: 'ACTION_CHATBOT_MESSAGES', data: { sender: 'bot', text: `${question?.ques[questionNoToAsk + 1]} ?` } })
                } else if (question?.ques?.length === ans?.length) {
                    let paylaod = {
                        userSelection: ans,
                        data: question?.item_data,
                        query: question?.query,
                    }
                    let res = await dispatch(CHATBOT_API(paylaod))
                    setAnswers([])
                    setQuestion(null)
                    setQuestionNoToAsk(0)
                    handleDataMaping(res)
                }
                setQuestionNoToAsk(q => q + 1)
                setInput('')
            }
        } else {
            sendMessage();
            setQuestionNoToAsk(0)
        }

    };


    const closeTab = () => {
        setQuestion(null)
        setQuestionNoToAsk(0)
        setInput('')
        if (setOpen) {
            setOpen(false)
        }
        navigate('/app/order')
    }

    const openInNewTab = async () => {
        navigate('/app/chatbot')
    }

    const closeWindow = () => {
        navigate('/app/order')
    }

    return (
        <div className=" relative w-full h-[96%] shadow rounded-lg bg-white">
            {/* Header of chatroom  */}
            <div className="px-2 flex justify-between items-center h-20 border border-b">
                <div className="flex justify-start items-center space-x-2">
                    <img className="w-12 h-12" alt="chaticon" src={chatIcon} />
                    <h1 className=" text-blue-950 font-semibold text-[20px] ">
                        AJAFFE ASSISTANT
                    </h1>
                </div>

                <div>
                    {location.pathname === '/app/chatbot' ? <IconButton size='small' onClick={closeWindow}>
                        <Close
                            color='action'
                            fontSize='medium'
                        />
                    </IconButton> : <IconButton size='small' onClick={openInNewTab}>
                        <OpenInFullSharp
                            color='action'
                            fontSize='small'
                        />
                    </IconButton>}
                    {setOpen && <IconButton size='small' onClick={closeTab}>
                        <Close
                            color='action'
                            fontSize='medium'
                        />
                    </IconButton>}
                </div>
            </div>
            <div className="h-[90%] flex flex-col justify-between">
                <ScrollToBottom ref={scrollRef} className='h-full'>
                    <div className="flex-grow pb-40 px-3 pt-2 space-y-4 overflow-y-auto">
                        {/* Chat Messages */}
                        {messages?.map((message, index) => {
                            return <div key={index + message?.text} className={` space-x-1 flex ${message?.sender === 'user' ? 'justify-end' : 'justify-start'}`}>
                                {message?.sender !== 'user' && <img className="w-10 h-10" alt="chaticon" src={chatIcon} />}
                                <div
                                    className={`max-w-sm p-2 ${message?.sender === 'user' ? 'min-w-20 rounded-tl-xl rounded-bl-xl rounded-br-xl flex justify-end bg-blue-500 text-white' : 'bg-gray-200/80 border shadow-sm rounded-tr-xl rounded-bl-xl rounded-br-xl'}`}
                                >
                                    {message?.IMAGEID && <a href={`https://lh3.googleusercontent.com/d/${message?.IMAGEID}`} target='_blank'>
                                        <ImageWithFallbackAndLoader
                                            loader={true}
                                            className=' mt-2 w-full object-cover rounded-lg '
                                            fallbackSrc={orderBoxImage}
                                            src={`https://lh3.googleusercontent.com/d/${message?.IMAGEID}`}
                                            alt={'image link'}
                                        />
                                        <br />
                                    </a>}

                                    {message?.sender !== 'user' ? <span className="text-gray-700">{renderTextWithBold(message?.text, message)}</span> : <span>{message?.text}</span>}
                                </div>
                            </div>
                        })}
                        {chatapiCall ? <div className="flex flex-row items-center gap-2">
                            <img className="w-10 h-10" alt="chaticon" src={chatIcon} />
                            <div className="flex justify-start items-center space-x-2 ">
                                <span className="animate-bounce bg-gray-400 w-2 mb-1 h-2 rounded-full" />
                                <span className="animate-bounce bg-gray-300 w-2 h-2 rounded-full" />
                                <span className="animate-bounce bg-gray-400 w-2 mb-1 h-2 rounded-full" />
                                <span className="animate-bounce bg-gray-300 w-2 h-2 rounded-full" />
                                <span className="animate-bounce bg-gray-400 mb-1 w-2 h-2 rounded-full" />
                            </div>
                        </div> : ''}
                    </div>
                </ScrollToBottom>

                {/* Input Form */}
                <form onSubmit={handleSubmit} className="p-3 absolute -bottom-[22px] w-full bg-white ">
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            rows={1}
                            className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                            placeholder="Type your message..."
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                        />
                        <button
                            type="submit"
                            className=" w-12 h-12 bg-gradient-to-tr  from-gray-700 to-gray-400 text-white p-2 font-[300] rounded-full"
                        >
                            <Send />
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ChatroomLayout;

