import { AlertError, AlertSuccess } from "src/Services"
import { postMethodApiCall } from "src/Services/API"

const baseUrl = process.env.REACT_APP_BASE_URL

const getHeadersWithoutAuth = () => {
    return {
        "Content-Type": "application/json"
    }
}

export const OTP_GENERATOR = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/otp_generator`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                AlertSuccess(res?.data?.message)
                return { success: true, message: res?.data?.message }
            } else {
                AlertError(res?.data?.message)
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}

// get tenant code by subdomain name
export const OTP_VELIDATION = data => {
    return async dispatch => {
        try {
            const url = `${baseUrl}/otp_validation`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                localStorage.setItem('userinfo', JSON.stringify(res?.data))
                localStorage.setItem('token', res?.data?.token)
                return { success: true, data: res?.data }
            } else {
                AlertError(res?.data?.message)
                return { success: false, message: res?.data?.message }
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}
// get tenant code by subdomain name
export const CHATBOT_API = (data) => {
    return async dispatch => {
        try {
            dispatch({ type: 'ACTION_CHAT_API_CALL_TRUE', data: true })
            const url = `${baseUrl}/gpt_bot/chat`
            const res = await postMethodApiCall(url, getHeadersWithoutAuth(), data)
            if (res?.status === 200 || res?.status === 201) {
                dispatch({ type: 'ACTION_CHAT_API_CALL_TRUE', data: false })
                return res?.data
            } else {
                AlertError(res?.data?.message)
                dispatch({ type: 'ACTION_CHAT_API_CALL_TRUE', data: false })
                return null
            }
        } catch (error) {
            console.log((error?.response?.data).replace(/\\/g, ""))
        }
    }
}
